import React from 'react';
import { useField } from 'formik';

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className='mx-1 mb-4'>
      <label
        htmlFor={props.id || props.name}
        className='mb-2 block text-sm font-bold'
      >
        {label}
        <span className='text-gray-700'>
          {props.required ? '' : ' (Optional)'}
        </span>
      </label>
      <input
        {...field}
        {...props}
        className={`focus:shadow-outline w-full max-w-xs appearance-none rounded border bg-gray-100 py-2 px-3 leading-tight text-gray-700 placeholder-gray-500 shadow focus:outline-none ${
          meta.touched && meta.error ? 'border-red-500' : ''
        }`}
      />
      {meta.touched && meta.error ? (
        <div className='mt-1 text-xs italic text-red-500'>{meta.error}</div>
      ) : null}
    </div>
  );
};

export default TextInput;
