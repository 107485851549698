import React from 'react';
import {
  ArchiveIcon,
  CalendarIcon,
  QuestionMarkCircleIcon,
  MailIcon,
  SpeakerphoneIcon,
  PhoneIcon,
  LocationMarkerIcon,
  RefreshIcon,
  GiftIcon,
  BookmarkAltIcon,
  SupportIcon,
  MenuIcon,
} from '@heroicons/react/outline';

export const getHeroIconFromString = (iconName, className) => {
  switch (iconName) {
    case 'Archive': {
      return <ArchiveIcon className={className} />;
    }
    case 'Calendar': {
      return <CalendarIcon className={className} />;
    }
    case 'QuestionMarkCircle': {
      return <QuestionMarkCircleIcon className={className} />;
    }
    case 'Mail': {
      return <MailIcon className={className} />;
    }
    case 'Speakerphone': {
      return <SpeakerphoneIcon className={className} />;
    }
    case 'Phone': {
      return <PhoneIcon className={className} />;
    }
    case 'LocationMarker': {
      return <LocationMarkerIcon className={className} />;
    }
    case 'Menu': {
      return <MenuIcon className={className} />;
    }
    case 'Refresh': {
      return <RefreshIcon className={className} />;
    }
    case 'Gift': {
      return <GiftIcon className={className} />;
    }
    case 'BookmarkAlt': {
      return <BookmarkAltIcon className={className} />;
    }
    case 'Support': {
      return <SupportIcon className={className} />;
    }
  }
};
