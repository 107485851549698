import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from '../image';
import { RichText } from 'prismic-reactjs';
import { getHeroIconFromString } from '../../utils/getHeroIconFromString';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NewNavbar = ({ showCompanyName, bgColor }) => {
  const staticData = useStaticQuery(graphql`
    query navbarQuery {
      prismicCompanyLogo {
        data {
          company_name
          logo {
            gatsbyImageData(width: 100)
            alt
          }
        }
      }
      prismicNavbar {
        data {
          body {
            ... on PrismicNavbarDataBodyMenu {
              primary {
                is_featured
                link_label {
                  raw
                }
                link_url {
                  url
                }
                link_url_anchor
              }
              slice_type
              items {
                link_url {
                  url
                }
                link_label {
                  raw
                }
                link_url_anchor
                description {
                  raw
                }
                icon
              }
            }
          }
        }
      }
    }
  `);

  const menuItems = staticData.prismicNavbar.data.body.filter(m => m.primary.link_label.raw[0].text.toLowerCase() !== 'legal disclaimer');
  const featuredMenuItems = menuItems.filter((m) => m.primary.is_featured);
  const menuItemsWithNoSubItems = menuItems.filter(
    (m) =>
      m.items.length === 0 &&
      RichText.asText(m.primary.link_label.raw) !== 'Purchase Knife Sleeves'
  );
  const nonFeaturedMenuItems = menuItems.filter((m) => !m.primary.is_featured);

  const companyInfoData = staticData.prismicCompanyLogo.data;
  const companyLogoImage = companyInfoData.logo.gatsbyImageData;
  const companyLogoAlt = companyInfoData.logo.alt;

  return (
    <Popover>
      <div
        className={`relative rounded-b px-4 pt-2 sm:px-6 lg:px-8 ${bgColor}`}
      >
        <nav
          className='relative flex items-center justify-between sm:h-20 lg:justify-start'
          aria-label='Global'
        >
          <div className='flex flex-shrink-0 flex-grow items-center lg:flex-grow-0'>
            <div className='flex w-full items-center justify-between md:w-auto'>
              <a href='/'>
                <span className='sr-only'>Logo</span>
                <Image
                  image={companyLogoImage}
                  alt={companyLogoAlt}
                  className='m-1 h-16 w-16'
                />
              </a>
              {showCompanyName ? (
                <div className='md:ml-4'>
                  <a href='/'>
                    <span className='font-semibold text-primary'>
                      {companyInfoData.company_name}
                    </span>
                  </a>
                </div>
              ) : null}
              <div className='-mr-2 flex items-center md:hidden'>
                <Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary'>
                  <span className='sr-only'>Open main menu</span>
                  <MenuIcon className='h-6 w-6' aria-hidden='true' />
                </Popover.Button>
              </div>
            </div>
          </div>
          <Popover.Group as='nav' className='ml-6 hidden space-x-4 md:flex'>
            <Popover className='relative'>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group inline-flex items-center rounded-md bg-white text-sm font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2'
                    )}
                  >
                    <span>
                      {RichText.asText(
                        featuredMenuItems[0].primary.link_label.raw
                      )}
                    </span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-1 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden='true'
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-200'
                    enterFrom='opacity-0 translate-y-1'
                    enterTo='opacity-100 translate-y-0'
                    leave='transition ease-in duration-150'
                    leaveFrom='opacity-100 translate-y-0'
                    leaveTo='opacity-0 translate-y-1'
                  >
                    <Popover.Panel className='absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-full lg:ml-0 lg:-translate-x-1/3'>
                      <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                        <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8'>
                          {featuredMenuItems.map((item) => {
                            return item.items.map((subItem, index) => {
                              return (
                                <Link
                                  key={index}
                                  to={`${subItem.link_url.url}${subItem.link_url_anchor
                                      ? '#' + subItem.link_url_anchor
                                      : ''
                                    }`}
                                  className='-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50'
                                >
                                  {getHeroIconFromString(
                                    subItem.icon,
                                    'flex-shrink-0 h-6 w-6 text-primary'
                                  )}
                                  <div className='ml-4'>
                                    <p className='text-base font-medium text-gray-900'>
                                      {RichText.asText(subItem.link_label.raw)}
                                    </p>
                                    <p className='mt-1 text-sm text-gray-500'>
                                      {RichText.asText(subItem.description.raw)}
                                    </p>
                                  </div>
                                </Link>
                              );
                            });
                          })}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            {menuItemsWithNoSubItems.map((menuItem, index) => {
              return (
                <div>
                  <Link
                    key={index}
                    to={`${menuItem.primary.link_url.url}${menuItem.primary.link_url_anchor
                        ? '#' + menuItem.primary.link_url_anchor
                        : ''
                      }`}
                    className='text-sm font-medium text-gray-500 hover:text-gray-900'
                  >
                    {RichText.asText(menuItem.primary.link_label.raw)}
                  </Link>
                </div>

              );
            })}

            <Popover className='relative'>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group inline-flex items-center rounded-md bg-white text-sm font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2'
                    )}
                  >
                    <span>More</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden='true'
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-200'
                    enterFrom='opacity-0 translate-y-1'
                    enterTo='opacity-100 translate-y-0'
                    leave='transition ease-in duration-150'
                    leaveFrom='opacity-100 translate-y-0'
                    leaveTo='opacity-0 translate-y-1'
                  >
                    <Popover.Panel className='absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0'>
                      <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                        <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8'>
                          {nonFeaturedMenuItems.map((item) => {
                            return item.items.map((subItem, index) => {
                              return (
                                <Link
                                  key={index}
                                  to={`${subItem.link_url.url}${subItem.link_url_anchor
                                      ? '#' + subItem.link_url_anchor
                                      : ''
                                    }`}
                                  className='-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50'
                                >
                                  {getHeroIconFromString(
                                    subItem.icon,
                                    'flex-shrink-0 h-6 w-6 text-primary'
                                  )}
                                  <div className='ml-4'>
                                    <p className='text-base font-medium text-gray-900'>
                                      {RichText.asText(subItem.link_label.raw)}
                                    </p>
                                    <p className='mt-1 text-sm text-gray-500'>
                                      {RichText.asText(subItem.description.raw)}
                                    </p>
                                  </div>
                                </Link>
                              );
                            });
                          })}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter='duration-150 ease-out'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='duration-100 ease-in'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'
      >
        <Popover.Panel
          focus
          className='absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden'
        >
          <div className='overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5'>
            <div className='flex items-center justify-between px-5 pt-4'>
              <div>
                <img
                  className='h-8 w-auto'
                  src='https://images.prismic.io/noon-sharpening/b39070e0-ca97-45bb-9dff-fe11552616e4_image.png?width=100,auto=compress,format'
                  alt=''
                />
              </div>
              <div className='-mr-2'>
                <Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary'>
                  <span className='sr-only'>Close main menu</span>
                  <XIcon className='h-6 w-6' aria-hidden='true' />
                </Popover.Button>
              </div>
            </div>
            <div className='mt-6 px-5 '>
              <nav className='grid gap-y-8'>
                {featuredMenuItems.map((item) => {
                  return item.items.map((subItem, index) => {
                    return (
                      <Link
                        key={index}
                        to={`${subItem.link_url.url}${subItem.link_url_anchor
                            ? '#' + subItem.link_url_anchor
                            : ''
                          }`}
                        className='-m-3 flex items-center rounded-md p-3 hover:bg-gray-50'
                      >
                        {getHeroIconFromString(
                          subItem.icon,
                          'flex-shrink-0 h-6 w-6 text-primary'
                        )}
                        <span className='ml-3 text-base font-medium text-gray-900'>
                          {RichText.asText(subItem.link_label.raw)}
                        </span>
                      </Link>
                    );
                  });
                })}
              </nav>
            </div>
            <div className='space-y-6 py-6 px-5'>
              <div className='grid grid-cols-2 gap-y-4 gap-x-8'>
                {menuItemsWithNoSubItems.map((menuItem, index) => {
                  return (
                    <Link
                      key={index}
                      to={`${menuItem.primary.link_url.url}${menuItem.primary.link_url_anchor
                          ? '#' + menuItem.primary.link_url_anchor
                          : ''
                        }`}
                      className='text-base font-medium text-gray-900 hover:text-gray-700'
                    >
                      {RichText.asText(menuItem.primary.link_label.raw)}
                    </Link>
                  );
                })}

                {nonFeaturedMenuItems.map((item) => {
                  return item.items.map((subItem, index) => {
                    return (
                      <Link
                        key={index}
                        to={`${subItem.link_url.url}${subItem.link_url_anchor
                            ? '#' + subItem.link_url_anchor
                            : ''
                          }`}
                        className='text-base font-medium text-gray-900 hover:text-gray-700'
                      >
                        {RichText.asText(subItem.link_label.raw)}
                      </Link>
                    );
                  });
                })}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default NewNavbar;
