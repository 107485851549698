import React from 'react';
import { Field, useField } from 'formik';
import { phoneNumberMask } from '../../utils/constants';
import MaskedInput from 'react-text-mask';

const PhoneInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Field name={props.name}>
      {() => {
        return (
          <div className='mb-4'>
            <label
              htmlFor={props.id || props.name}
              className='mb-2 block text-sm font-bold'
            >
              {label}
              {props.required ? '' : ''}
            </label>
            <MaskedInput
              {...field}
              mask={phoneNumberMask}
              name={props.name}
              placeholder='Enter your phone number'
              type='text'
              className={`focus:shadow-outline w-full appearance-none rounded border bg-gray-100 py-2 px-3 leading-tight text-gray-700 placeholder-gray-500 shadow focus:outline-none sm:max-w-xs ${
                (meta.touched && meta.error) ||
                (props.meta && props.meta.touched && props.meta.error)
                  ? 'border-red-500'
                  : ''
              }`}
            />
            {meta.touched && meta.error ? (
              <div className='mt-1 text-xs italic text-red-500'>
                {meta.error}
              </div>
            ) : null}
          </div>
        );
      }}
    </Field>
  );
};

export default PhoneInput;
