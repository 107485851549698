import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const Image = ({ image, imgStyle, loading, className, alt }) => {
  return (
    <GatsbyImage
      image={image}
      imgStyle={imgStyle}
      loading={loading}
      className={className}
      alt={alt}
    />
  );
};

export default Image;
