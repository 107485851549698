import {
  faFacebook,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

export const getSocialMediaIconFromType = (socialMediaType) => {
  switch (socialMediaType.toLowerCase()) {
    case 'twitter': {
      return faTwitter;
    }
    case 'facebook': {
      return faFacebook;
    }
    case 'instagram': {
      return faInstagram;
    }
  }
};
