import React from 'react';
import { RichText } from 'prismic-reactjs';
import SectionTitle from '../sectionTitle';
import htmlSerializer from '../../utils/htmlSerializer';
import FaqItem from '../faqItem';

const FaqSection = (slice) => {
  const { title, description, use_primary_color_background } =
    slice.slice.primary;
  const { items } = slice.slice;

  const descriptionComponent = (
    <div
      className={`flex flex-col items-center ${
        use_primary_color_background ? 'text-gray-100' : ''
      }`}
    >
      <RichText render={description.raw} htmlSerializer={htmlSerializer} />
    </div>
  );

  return (
    <div
      id={RichText.asText(title.raw)}
      className={`flex w-full flex-col items-center px-8 ${
        use_primary_color_background ? 'bg-primary text-gray-100' : ''
      }`}
    >
      <div className='w-full py-12 md:w-2/3'>
        <SectionTitle
          title={title.raw}
          className={`${
            use_primary_color_background ? 'text-gray-100' : 'text-primary'
          }`}
        />
        <div className='flex w-full flex-col items-center justify-center'>
          {descriptionComponent}
          <div className='w-full md:w-2/3'>
            {items.map((item, index) => {
              const { title, text } = item;
              return <FaqItem key={index} title={title} text={text} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
