import { RichText } from 'prismic-reactjs';
import React from 'react';
import htmlSerializer from '../../utils/htmlSerializer';
import SectionTitle from '../sectionTitle';
import Image from '../image';

const SectionFeaturingLink = (slice) => {
  const {
    title,
    link,
    embed_in_page,
    description,
    use_primary_color_background,
    image,
  } = slice.slice.primary;

  const descriptionComponent = link.url ? (
    <div
      className={`text-lg md:w-3/5 md:pr-12 md:text-justify ${
        use_primary_color_background ? 'text-gray-100' : ''
      }`}
    >
      <RichText render={description.raw} htmlSerializer={htmlSerializer} />
    </div>
  ) : (
    <div
      className={`flex flex-col items-center text-lg ${
        use_primary_color_background ? 'text-gray-100' : ''
      }`}
    >
      <RichText render={description.raw} htmlSerializer={htmlSerializer} />
    </div>
  );

  return (
    <div
      id={RichText.asText(title?.raw)}
      className={`flex w-full flex-col items-center px-8 ${
        use_primary_color_background ? 'bg-primary text-gray-100' : ''
      }`}
    >
      <div className='w-full py-12 md:w-2/3'>
        <SectionTitle
          title={title?.raw}
          className={`${
            use_primary_color_background ? 'text-gray-100' : 'text-primary'
          }`}
        />
        <div className='flex w-full flex-col items-center justify-center md:flex-row'>
          {description.raw[0].text ? descriptionComponent : null}
          {embed_in_page && link.url ? (
            <div className='md:w-2/5'>
              <iframe
                src={link.url}
                className='my-8 hidden w-full md:block'
                style={{ minHeight: `25rem`, maxHeight: `40rem` }}
                loading='lazy'
              />
              <iframe
                src={`${link.url}`}
                className='block w-full md:hidden'
                style={{
                  minHeight: `25rem`,
                  maxHeight: `40rem`,
                  borderWidth: `0`,
                }}
                frameBorder='0'
                loading='lazy'
              />
            </div>
          ) : null}
          {link.url && !embed_in_page ? (
            <a href={link.url} rel='noopener noreferrer' target='_blank'>
              <Image image={image.gatsbyImageData} alt={image.alt} />
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SectionFeaturingLink;
