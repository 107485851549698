import React, { useEffect, useState } from 'react';
import * as axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCaretLeft,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import htmlSerializer from '../utils/htmlSerializer';
import { RichText } from 'prismic-reactjs';
import { Bars } from 'svg-loaders-react';
import { getStandardizedDateTimeFromGCalDateTime } from '../utils/gCalDateUtils';
import { LocationMarker } from '../svgs/locationMarker';
import { Clock } from '../svgs/clock';

const RbrsGCal = (slice) => {
  if (!slice.slice) {
    return null;
  }

  const { title, description } = slice.slice.primary;

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [calData, setCalData] = useState(null);
  const [isLoadingCalendarData, setIsLoadingCalendarData] = useState(false);

  const getCalendarData = async (month, year) => {
    const timeMin = `${year}-${month}-01T00%3A00%3A00Z`;
    const timeMax = `${year}-${month}-${getMaxDay(month)}T23%3A59%3A59Z`;

    try {
      setIsLoadingCalendarData(true);

      const response = await axios.get(
        `${process.env.GATSBY_RBRS_GCAL_API}?googleCalendarName=${process.env.GATSBY_GOOGLE_CALENDAR_NAME}&timeMin=${timeMin}&timeMax=${timeMax}`
      );
      setCalData(response.data.items);

      setIsLoadingCalendarData(false);
    } catch {
      setIsLoadingCalendarData(false);
    }
  };

  useEffect(async () => {
    await getCalendarData(currentMonth, currentYear);
  }, []);

  const isLeapYear = (year) => {
    return new Date(year, 1, 29).getDate() === 29;
  };

  const getMaxDay = (month) => {
    switch (+month) {
      case 2: {
        return isLeapYear(currentYear) ? 29 : 28;
      }
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12: {
        return 31;
      }
      case 4:
      case 6:
      case 9:
      case 11: {
        return 30;
      }
    }
  };

  const dateOptions = { weekday: 'long', month: 'long', day: 'numeric' };
  const timeOptions = { timeStyle: 'short', timeZone: 'America/New_York' };

  const months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 },
  ];

  const range = (start, end, length = end - start + 1) =>
    Array.from({ length }, (_, i) => start + i);

  const yearBoundary = new Date().getFullYear();
  const years = range(yearBoundary - 20, yearBoundary + 20);

  const handleSetCurrentMonth = (month) => {
    const currentMonthAsNumber = +currentMonth;
    if (currentMonthAsNumber < 12 && currentMonthAsNumber > 1) {
      setCurrentMonth(month);
      getCalendarData(month, currentYear);
    } else if (currentMonthAsNumber === 12 && month > currentMonthAsNumber) {
      setCurrentMonth(1);
      setCurrentYear(currentYear + 1);
      getCalendarData(1, currentYear + 1);
    } else if (
      (currentMonthAsNumber === 12 && month < currentMonthAsNumber) ||
      month === 12
    ) {
      setCurrentMonth(month);
      getCalendarData(month, currentYear);
    } else if (currentMonthAsNumber === 1 && month > currentMonthAsNumber) {
      setCurrentMonth(month);
      getCalendarData(month, currentYear);
    } else if (
      (currentMonthAsNumber === 1 && month < currentMonthAsNumber) ||
      month === 0
    ) {
      setCurrentMonth(12);
      setCurrentYear(currentYear - 1);
      getCalendarData(12, currentYear - 1);
    }
  };

  const handleSetCurrentYear = (year) => {
    setCurrentYear(year);
    getCalendarData(currentMonth, year);
  };

  const descriptionComponent = (
    <div className={`mb-12 text-lg md:text-justify`}>
      <RichText render={description.raw} htmlSerializer={htmlSerializer} />
    </div>
  );

  let content = null;

  if (!calData && isLoadingCalendarData) {
    content = (
      <div className='mt-12 flex w-full flex-col items-center'>
        <Bars stroke='#002868' fill='#002868' className='lg:36 w-12 md:w-24' />
      </div>
    );
  } else if (calData && calData.length === 0 && !isLoadingCalendarData) {
    content = (
      <div className='mt-12 flex w-full flex-col items-center'>
        <p className='text-center text-2xl text-primary'>No events found</p>
      </div>
    );
  } else if (calData && calData.length > 0 && !isLoadingCalendarData) {
    content = calData.map((item, index) => {
      return (
        <div key={index} className='mb-4 rounded shadow-lg'>
          <div className='rounded-t bg-primary px-4 py-2 text-gray-100 shadow'>
            <div className='flex justify-between'>
              <span
                title={getStandardizedDateTimeFromGCalDateTime(
                  item.start
                ).toLocaleDateString()}
              >
                {getStandardizedDateTimeFromGCalDateTime(
                  item.start
                ).toLocaleDateString(undefined, dateOptions)}
              </span>
              <a href={item.htmlLink} rel='noopener noreferrer' target='_blank'>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  size='1x'
                  className='mx-1'
                />
              </a>
            </div>
          </div>
          <div className='mb-2 rounded-b px-4 pb-4'>
            <div className='flex items-center justify-between py-4'>
              <p className='text-xl font-semibold'>{item.summary}</p>
            </div>
            {item.summary === 'Off' ? null : (
              <>
                <div className='flex items-center mb-4 text-sm text-gray-800 '>
                  <div className="text-primary opacity-25">
                    <Clock />
                  </div>
                  <span className="ml-1">
                    {getStandardizedDateTimeFromGCalDateTime(
                      item.start
                    ).toLocaleTimeString(undefined, timeOptions)}
                  </span>
                  {item.end.dateTime ? (
                    <>
                      <span>&nbsp;-&nbsp;</span>
                      <span>
                        {getStandardizedDateTimeFromGCalDateTime(
                          item.end
                        ).toLocaleTimeString(undefined, timeOptions)}
                      </span>
                    </>
                  ) : null}
                </div>
                {item.location ? (<a
                  href={`https://www.google.com/maps/search/?api=1&query=${item.location}`}
                  rel='noopener noreferrer'
                  target='_blank'
                  className='flex items-center text-primary hover:underline'
                >
                  <div className="opacity-25">
                    <LocationMarker />
                  </div>
                  <p className="ml-1">{item.location}</p>
                </a>) : null}
              </>
            )}
          </div>
        </div>
      );
    });
  }

  return (
    <>
      <div className='my-8 w-full px-4 md:w-2/3 md:px-0 mega:w-2/5'>
        <div className='flex flex-col items-center'>
          <div className='my-8 md:px-0'>
            <p className='text-3xl font-semibold text-primary'>
              {RichText.asText(title.raw)}
            </p>
          </div>
          {description.raw[0]?.text ? descriptionComponent : null}
        </div>
        <div className='flex w-full items-center justify-between'>
          <div>
            <select
              className='mr-1 rounded bg-gray-100 p-2'
              onChange={(e) => handleSetCurrentMonth(+e.target.value)}
              value={currentMonth}
            >
              {months.map((month, index) => {
                return (
                  <option key={index} value={month.value}>
                    {month.name}
                  </option>
                );
              })}
            </select>
            <select
              className='ml-1 rounded bg-gray-100 p-2'
              onChange={(e) => handleSetCurrentYear(+e.target.value)}
              value={currentYear}
            >
              {years.map((year, index) => {
                return (
                  <option key={index} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
          </div>
          <div className='flex'>
            <button
              className='mr-1 rounded bg-primary px-5 py-1 text-gray-100 disabled:opacity-50'
              onClick={() => handleSetCurrentMonth(+currentMonth - 1)}
            >
              <FontAwesomeIcon icon={faCaretLeft} size='1x' />
            </button>
            <button
              className='ml-1 rounded bg-primary px-5 py-1 text-gray-100 disabled:opacity-50'
              onClick={() => handleSetCurrentMonth(+currentMonth + 1)}
            >
              <FontAwesomeIcon icon={faCaretRight} size='1x' />
            </button>
          </div>
        </div>
      </div>
      <div className='min-h-screen w-full px-4 md:w-2/3 md:px-0 mega:w-2/5'>
        {content}
      </div>
    </>
  );
};

export default RbrsGCal;
