import { RichText } from 'prismic-reactjs';
import React, { useEffect } from 'react';
import htmlSerializer from '../../utils/htmlSerializer';
import SectionTitle from '../sectionTitle';

const GiftupSlice = (slice) => {
  const { title, description, giftup_script_url, giftup_data_site_id } =
    slice.slice.primary;

  useEffect(() => {
    const script = document.createElement('script');
    script.innerText = `
        (function (g, i, f, t, u, p, s) {
            g[u] = g[u] || function() { (g[u].q = g[u].q || []).push(arguments) };
            p = i.createElement(f);
            p.async = 1;
            p.src = t;
            s = i.getElementsByTagName(f)[0];
            s.parentNode.insertBefore(p, s);
        })(window, document, "script", "${giftup_script_url.url}", "giftup");
        `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className='mb-12 flex w-full flex-col items-center px-8 md:w-2/3 md:px-0'>
      <SectionTitle title={title.raw} />
      <div className='flex flex-col items-center text-lg'>
        <RichText render={description.raw} htmlSerializer={htmlSerializer} />
      </div>
      <div
        className='gift-up-target w-full'
        data-site-id={giftup_data_site_id}
        data-platform='Other'
      />
    </div>
  );
};

export default GiftupSlice;
