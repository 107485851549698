import React from 'react';
import { Link } from 'gatsby';

const LinkButton = ({ to, label }) => {
  return (
    <Link
      className='my-4 cursor-pointer rounded bg-gray-100 px-4 py-3 font-semibold text-primary shadow-lg transition duration-100 ease-in-out hover:bg-gray-200 hover:shadow-xl md:px-8 md:py-3 md:text-lg'
      to={to}
    >
      {label}
    </Link>
  );
};

export default LinkButton;
