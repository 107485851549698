import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as axios from 'axios';
import { RichText } from 'prismic-reactjs';
import TextInput from './textInput';
import PhoneInput from './phoneInput';
import TextArea from './textArea';
import ThankYou from './thankYou';

const ContactUsForm = ({
  formTitle,
  formPostUrl,
  formSubmitText,
  formFields,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <div className='my-12 mx-8 md:mx-0'>
      <div className='mb-4'>
        <p className={`my-4 text-3xl ${isSubmitted ? 'text-center' : ''}`}>
          {isSubmitted ? 'Thank you!' : RichText.asText(formTitle.raw)}
        </p>
        {isSubmitted ? null : (
          <div className='mb-4 text-sm text-gray-500'>
            <p>We will get back to you shortly.</p>
          </div>
        )}
        <hr />
      </div>
      {isSubmitted ? (
        <ThankYou />
      ) : (
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            message: '',
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required('Please fill out this field.'),
            email: Yup.string()
              .email('Invalid email address')
              .required('Please fill out this field.'),
            phoneNumber: Yup.string().matches(
              /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
              'Please enter valid phone number'
            ),
            message: Yup.string().required('Please fill out this field.'),
            _gotcha: Yup.string().matches(''),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            await axios.post(formPostUrl.url, values, {
              headers: { Accept: 'application/json' },
            });
            setSubmitting(false);
            setIsSubmitted(true);
          }}
        >
          {({ errors, touched, handleSubmit }) => {
            const hasErrors = Object.keys(errors).length > 0;

            return (
              <Form>
                <div className='flex flex-col justify-between sm:flex-row'>
                  <TextInput
                    required
                    label='First Name'
                    name='firstName'
                    type='text'
                    placeholder='Jane'
                  />
                  <TextInput
                    required
                    label='Last Name'
                    name='lastName'
                    type='text'
                    placeholder='Doe'
                  />
                </div>
                <TextInput
                  required
                  label='Email Address'
                  name='email'
                  type='email'
                  placeholder='jane@example.com'
                  className='focus:shadow-outline mb-6 w-full max-w-xs appearance-none rounded border bg-gray-200 py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none'
                />
                <PhoneInput
                  required
                  type='tel'
                  label='Phone Number'
                  name='phoneNumber'
                />
                <hr />
                <div className='my-4 font-semibold'>
                  <TextArea
                    required
                    label='Message'
                    name='message'
                    placeholder='How can we help?'
                  />
                </div>
                <input type='hidden' name='_gotcha' />
                <div className='mb-4 text-xs text-gray-700 sm:text-sm'>
                  <span>
                    A copy of your inquiry will be emailed to the email address
                    used above.
                  </span>
                </div>
                <div className='w-full sm:flex sm:justify-end'>
                  <button
                    disabled={hasErrors}
                    readOnly={hasErrors}
                    type='submit'
                    className={`text-md w-full rounded bg-primary py-1 px-2 font-semibold text-white shadow hover:bg-blue-600 sm:w-1/3 ${
                      hasErrors ? 'cursor-auto opacity-75' : 'hover:bg-blue-600'
                    }`}
                    onClick={!hasErrors ? (e) => handleSubmit(e) : null}
                  >
                    {formSubmitText}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default ContactUsForm;
