import * as React from 'react';
import { Elements } from 'prismic-richtext';

import { linkResolver } from './linkResolver';

// This function will be used to change the way the HTML is loaded
const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case Elements.paragraph:
      return (
        <p key={key} className='my-4 leading-loose'>
          {children}
        </p>
      );

    // Don't wrap images in a <p> tag
    case Elements.image:
      return <img key={key} src={element.url} alt={element.alt || ''} />;

    case Elements.hyperlink:
      return (
        <a
          key={key}
          href={element.data.url || linkResolver(element.data)}
          target={element.data.target}
          rel={element.data.target ? 'noopener' : undefined}
          className='underline'
        >
          {children}
        </a>
      );

    // Return null to stick with the default behavior
    default:
      return null;
  }
};

export default htmlSerializer;
