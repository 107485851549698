import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from '../image';
import { RichText } from 'prismic-reactjs';

const BlogRollSlice = (slice) => {
  const data = useStaticQuery(graphql`
    query BlogPostPageBlogRollQuery {
      allPrismicBlogPost {
        nodes {
          url
          data {
            blog_title
            blog_post_content {
              raw
            }
            body {
              ... on PrismicBlogPostDataBodyBackgroundHeroImage {
                id
                primary {
                  image {
                    alt
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { primary } = slice.slice;

  const { description, empty_feed_image } = primary;

  const emptyBlogRollComponent = (
    <div className='flex flex-col items-center justify-center px-8 md:px-0'>
      <p className='py-12 text-3xl font-bold text-gray-800'>Coming Soon!</p>
      <Image
        image={empty_feed_image.gatsbyImageData}
        alt={empty_feed_image.alt}
        className='w-full md:w-2/3'
      />
    </div>
  );

  const blogPostItemComponent = (node, index) => {
    const { url, data } = node;
    const { blog_title, body } = data;

    const excerpt = RichText.asText(data.blog_post_content.raw).slice(0, 100);

    return (
      <Link
        key={index}
        to={url}
        className='flex transform justify-between rounded shadow-lg duration-100 hover:-translate-y-1 hover:shadow-lg md:shadow my-2'
      >
        <Image
          image={body[0].primary.image.gatsbyImageData}
          alt={body[0].primary.image.alt}
          className='shadow-r w-4/5 rounded-tl rounded-bl md:w-1/3'
        />
        <div className='px-4 py-4'>
          <p className='pl-4 text-lg font-semibold text-gray-800'>
            {blog_title}
          </p>
          <p className='pl-4 text-sm text-gray-600'>{excerpt}...</p>
        </div>
      </Link>
    );
  };

  return (
    <div className='flex w-full flex-col items-center lg:px-20 mega:w-9/12'>
      <div className='px-4'>
        <p className='py-12 text-center text-2xl font-semibold text-gray-800 md:py-20 md:text-left'>
          {RichText.asText(description.raw)}
        </p>
        {/* <p className="italic text-gray-600 py-4 text-center md:text-left">{RichText.asText(description.raw)}</p> */}
      </div>
      <div className='flex w-full flex-col items-center justify-center py-12 px-8 md:w-2/3'>
        {data.allPrismicBlogPost.nodes.length > 0
          ? data.allPrismicBlogPost.nodes.map((node, index) => {
              return blogPostItemComponent(node, index);
            })
          : emptyBlogRollComponent}
      </div>
    </div>
  );
};

export default BlogRollSlice;
