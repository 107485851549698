import React from 'react';
import CallToAction from '../callToAction';

const CallToActionSlice = (slice) => {
  const { call_to_action_link, call_to_action_label, link_anchor_text } =
    slice.slice.primary;

  return (
    <CallToAction
      to={call_to_action_link.url}
      label={call_to_action_label}
      linkAnchor={link_anchor_text}
    />
  );
};

export default CallToActionSlice;
