import React from 'react';
import ContactUsForm from '../forms/contactUsForm';

const FormSectionSlice = (slice) => {
  const { title, form_post_url, form_submit_text } = slice.slice.primary;
  const items = slice.slice.items;

  return (
    <ContactUsForm
      formTitle={title}
      formPostUrl={form_post_url}
      formSubmitText={form_submit_text}
      formFields={items}
    />
  );
};

export default FormSectionSlice;
