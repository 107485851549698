import { RichText } from 'prismic-reactjs';
import React from 'react';
import Image from '../image';

const AlternateGrid = (slice) => {
  const { title } = slice.slice.primary;
  const items = slice.slice.items;

  return (
    <div
      id={RichText.asText(title.raw)}
      className='flex w-full flex-col items-center bg-primary text-gray-100'
    >
      <div className='w-full py-12 md:w-2/3'>
        <div className='px-8 py-4 text-center md:px-0 md:py-8'>
          <p className='text-2xl font-semibold md:text-3xl'>
            {title.raw[0].text}
          </p>
        </div>
        <div className='flex flex-col items-center justify-between px-8 md:flex-row md:flex-wrap md:px-0'>
          {items.map((item, index) => {
            const { description, optional_icon, title, link } = item;
            return (
              <div
                key={index}
                className='md:flexBasis20 my-4 text-center md:m-4'
                style={{ minHeight: `15rem` }}
              >
                <div className='flex transform flex-col items-center justify-center py-4 text-center transition-transform hover:scale-105 md:h-24 md:py-0'>
                  <Image
                    image={optional_icon.gatsbyImageData}
                    alt={optional_icon.alt}
                    className='w-auto rounded lg:w-7/12 mega:w-8/12'
                  />
                </div>
                {link && link.url ? (
                  <a
                    href={link.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='hover:underline'
                  >
                    <p className='text-center text-xl font-semibold'>
                      {title.raw[0].text}
                    </p>
                  </a>
                ) : (
                  <p className='text-center text-xl font-semibold'>
                    {title.raw[0].text}
                  </p>
                )}
                {description.raw.length > 0 ? (
                  <p className='md:max-w-xs'>{description.raw[0].text}</p>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AlternateGrid;
