import React from 'react';
import Helmet from 'react-helmet';

const SEO = ({ description, title, site }) => {
  const metaDescription = description || site.siteMetadata.description;
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: 'metaDescription',
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    >
      <meta
        name='google-site-verification'
        content='uIidjtFiXvT_oQAtSnDkL9EviHJPzRonHAm-AmOco3o'
      />
      <link rel='stylesheet' href='https://rsms.me/inter/inter.css' />
      <script type="text/javascript" src="https://cdn.eztexting.com/assets/signup-forms/signup-forms-validation.min.js"></script>
      <link rel="stylesheet" href="https://cdn.eztexting.com/assets/signup-forms/signup-forms-styles.min.css" type="text/css" />
    </Helmet>
  );
};

export default SEO;
