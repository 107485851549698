import React from 'react';
import { RichText } from 'prismic-reactjs';

const SectionTitle = ({ title, className }) => {
  return (
    <div className='px-2 py-10 text-center md:px-8 md:px-0'>
      <p className={`text-3xl font-semibold ${className}`}>
        {RichText.asText(title)}
      </p>
    </div>
  );
};

export default SectionTitle;