import { RichText } from 'prismic-reactjs';
import React from 'react';
import Image from '../image';
import SectionTitle from '../sectionTitle';

const LinksCollection = (slice) => {
  const { title } = slice.slice.primary;
  const links = slice.slice.items;

  return (
    <div id={RichText.asText(title.raw)} className='flex flex-col items-center'>
      <SectionTitle title={title.raw} />
      {links.map((link, index) => {
        return (
          <div
            key={index}
            className='my-12 mx-8 flex w-auto items-center text-lg font-semibold text-primary md:w-1/3'
          >
            <Image
              image={link.optional_image.gatsbyImageData}
              className='mr-4 w-1/3'
            />
            <a
              href={link.link.url}
              target='_blank'
              rel='noopener noreferrer'
              className='hover:underline'
            >
              <p>{link.link_label}</p>
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default LinksCollection;
