import React from 'react';
import LinkButton from './linkButton';

const CallToAction = ({ to, label, linkAnchor }) => {
  return (
    <div className='my-12'>
      <LinkButton
        to={`${linkAnchor ? to + '#' + linkAnchor : to}`}
        label={label}
      />
    </div>
  );
};

export default CallToAction;
