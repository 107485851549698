import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Image from './image';

const Logo = ({ image, className, alt }) => {
  return (
    <Link to='/'>
      <Image
        image={image}
        imgStyle={{ objectFit: `fill` }}
        className={`${className}`}
        alt={alt}
      />
    </Link>
  );
};

Logo.propTypes = {
  logoFluid: PropTypes.object,
  className: PropTypes.string,
};

export default Logo;
