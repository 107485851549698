import React, { useState } from 'react';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../utils/htmlSerializer';

const FaqItem = ({ title, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      onClick={() => setIsOpen(!isOpen)}
      className='my-4 rounded bg-gray-100 px-3 py-2 text-primary shadow'
    >
      {isOpen ? (
        <div className='flex cursor-pointer flex-col items-center justify-between'>
          <div className='flex w-full justify-between'>
            <span className='text-lg font-semibold'>
              {RichText.asText(title.raw)}
            </span>
            <span className='text-lg font-semibold text-gray-600'>-</span>
          </div>
          <RichText render={text.raw} htmlSerializer={htmlSerializer} />
        </div>
      ) : (
        <div className='flex cursor-pointer items-center justify-between'>
          <span className='text-lg font-semibold'>
            {RichText.asText(title.raw)}
          </span>
          <span className='text-lg font-semibold text-gray-600'>+</span>
        </div>
      )}
    </div>
  );
};

export default FaqItem;
