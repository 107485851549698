import React from 'react';
import { useField } from 'formik';

const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className='mb-4'>
      <label
        htmlFor={props.id || props.name}
        className='mb-2 block text-sm font-bold'
      >
        {label}
        {props.required ? '' : ''}
      </label>
      <textarea
        {...field}
        {...props}
        className={`focus:shadow-outline h-40 w-full resize-none appearance-none rounded border bg-gray-100 py-2 px-3 leading-tight text-gray-700 placeholder-gray-500 shadow focus:outline-none ${
          meta.touched && meta.error ? 'border-red-500' : ''
        }`}
      />
      {meta.touched && meta.error ? (
        <div className='mt-1 text-xs italic text-red-500'>{meta.error}</div>
      ) : null}
    </div>
  );
};

export default TextArea;
