import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from './logo';
import { getSocialMediaIconFromType } from '../utils/getSocialMediaIconFromType';
import { RichText } from 'prismic-reactjs';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      allPrismicSocialMedia {
        nodes {
          data {
            social_media_type
            social_media_account_link {
              url
            }
          }
        }
      }
      prismicNavbar {
        data {
          body {
            ... on PrismicNavbarDataBodyMenu {
              primary {
                is_featured
                link_label {
                  raw
                }
                link_url {
                  url
                }
                link_url_anchor
              }
              slice_type
              items {
                link_url {
                  url
                }
                link_label {
                  raw
                }
                link_url_anchor
                description {
                  raw
                }
                icon
              }
            }
          }
        }
      }
      prismicCompanyLogo {
        data {
          company_name
          logo {
            gatsbyImageData(width: 100)
            alt
          }
        }
      }
      prismicFooter {
        data {
          address_1 {
            raw
          }
          email_address {
            url
          }
          email_address_label {
            raw
          }
          copyright_info {
            raw
          }
          address_2 {
            raw
          }
          contact_phone_number {
            raw
          }
        }
      }
    }
  `);

  const {
    prismicCompanyLogo,
    prismicNavbar,
    prismicFooter,
    allPrismicSocialMedia,
  } = data;

  const { company_name, logo } = prismicCompanyLogo.data;
  const {
    address_1,
    address_2,
    email_address,
    email_address_label,
    copyright_info,
    contact_phone_number,
  } = prismicFooter.data;
  const menuItems = prismicNavbar.data.body;

  const socialMedias = allPrismicSocialMedia.nodes;

  return (
    <footer className='flex items-center bg-gray-100 py-4 text-gray-700'>
      <div className='w-full px-3 md:mx-12 md:px-12'>
        <div className='flex flex-col items-center justify-between md:flex-row md:justify-around'>
          <div className='mr-1 flex items-center md:w-8/12 lg:w-auto'>
            <div className='mr-4 mb-1 w-full self-start pr-1 pb-1'>
              <Logo
                image={logo.gatsbyImageData}
                className='max-w-xxxxxs mt-4'
                alt={logo.alt}
              />
            </div>
            <div className='mt-4 flex flex-col text-left text-sm'>
              <p className='py-1 text-lg font-semibold'>{company_name}</p>
              <p className='py-1'>{address_1.raw[0].text}</p>
              <p className='py-1'>{address_2.raw[0].text}</p>
              <a href={email_address.url} className='py-1 text-primary'>
                {email_address_label.raw[0].text}
              </a>
              <a
                href={`tel:${contact_phone_number.raw[0].text}`}
                className='py-1 text-primary'
              >
                {contact_phone_number.raw[0].text}
              </a>
              <div className='flex'>
                {socialMedias.map((sm, index) => {
                  return (
                    <div key={index} className='mr-2 mt-2'>
                      <a
                        href={sm.data.social_media_account_link.url}
                        target='_blank'
                      >
                        <FontAwesomeIcon
                          icon={getSocialMediaIconFromType(
                            sm.data.social_media_type
                          )}
                          size='2x'
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='my-3 flex w-full items-center justify-between font-medium text-primary md:w-4/12'>
            <div className='flex h-48 flex-col flex-wrap justify-center md:h-64'>
              {menuItems
                .filter(
                  (m) => RichText.asText(m.primary.link_label.raw) !== 'More'
                )
                .map((menuItem, index) => {
                  const { link_label, link_url, link_url_anchor } =
                    menuItem.primary;

                  let subItemsContent = null;

                  if (menuItem.items.length > 0) {
                    subItemsContent = menuItem.items.map((item, index2) => {
                      return (
                        <Link
                          key={index2}
                          className='p-1 text-xs hover:font-medium hover:text-primary hover:underline md:text-sm'
                          to={`${item.link_url.url}${
                            item.link_url_anchor
                              ? '#' + item.link_url_anchor
                              : ''
                          }`}
                        >
                          <span>{item.link_label.raw[0].text}</span>
                        </Link>
                      );
                    });
                  }

                  return (
                    <>
                      <Link
                        key={index}
                        className='p-1 text-xs hover:font-medium hover:text-primary hover:underline md:text-sm'
                        to={`${link_url.url}${
                          link_url_anchor ? '#' + link_url_anchor : ''
                        }`}
                      >
                        <span>{link_label.raw[0].text}</span>
                      </Link>
                      {subItemsContent}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
        <div className='text-center text-xs'>
          <p>
            {copyright_info.raw[0].text}&copy; {new Date().getFullYear()}
          </p>
        </div>
        <div className='text-center text-xs'>
          <p>
            Website created by{' '}
            <a
              href='https://redbrickroadstudio.com'
              rel='noopener noreferrer'
              target='_blank'
            >
              Red Brick Road Studio
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
