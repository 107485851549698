import React from 'react';
import { Link } from 'gatsby';

const ThankYou = () => {
  return (
    <div className='flex flex-col items-center text-center text-gray-800'>
      <p className='mt-4'>We will get back to you shortly!</p>
      <div className='xs:w-1/3 sm:w-fullfont-semibold text-md mt-12 rounded bg-primary py-1 px-2 text-white shadow hover:bg-blue-600'>
        <Link to='/'>Go to Home page</Link>
      </div>
    </div>
  );
};

export default ThankYou;
