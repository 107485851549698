import React, { useEffect } from 'react';
import SectionTitle from '../sectionTitle';

const ConstantContactSlice = (slice) => {
  useEffect(() => {
    const scriptVar = document.createElement('script');
    scriptVar.innerText = `var _ctct_m = "${slice.slice.primary.constant_contact_script_variable}";`;
    document.body.appendChild(scriptVar);

    const script = document.createElement('script');

    script.id = 'signupScript';
    script.src = slice.slice.primary.constant_contact_script_url.url.replace(
      'https://',
      ''
    );

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(scriptVar);
    };
  }, []);

  return (
    <div className='mb-12 flex w-full flex-col items-center px-8 md:w-2/3 md:px-0'>
      <div>
        <form class="c-web-form" ngNoForm method="POST" action="https://widgy-lb.prd.cfire.io/EZ/widget/subscribe">
          <input type="hidden" name="serializedData" id="jwt" value="eyJlbmMiOiJBMTI4R0NNIiwiYnJhbmQiOiJFWiIsImFsZyI6ImRpciIsIndpZGdldElkIjoiZDYwOWZjYmItM2M5MC00NTZhLWJjYmYtZDIxYzI2YmE5N2EzIn0..V2DJtbPSZtpvZJ4r.QId4KEcm8yw4QD2KRNXcy4hjQ4bCx15741_yvsT0VDxxujpcH244egTO2XcorOUgdSiM7-yW7GupUJ-aArOOD4Y1LEz0rcppSiVqXFfHr32PIw0eI6dhPl4c8hfCD-mRwGIs3vRdCSPaJ4g38zZlt2QoYpaLBBB4vKU0vEyE1Gb8bWR97EnTISVjHNpnYJbD4WU19xXPrlaNG8EMnn4rTpG4D6EIktGVr2EKUzNtiKFd6g.tsJbdb7mEn4_UKufXVmtzw" />
          <div class="c-web-form__title">Sign up for sharpening notifications!</div>
          <div class="c-web-form__description">Sign up to be notified when we will be sharpening in your area</div>
          <ul class="c-web-form__be-errors"></ul>
          <div>
            <div class="c-web-form__control-group">
              <label class="c-web-form__label c-web-form-uppercase-text" for="c-web-form-phoneNumber">Phone Number*</label>
              <div class="c-web-form__error" id="c-web-form-phoneNumber-error"></div>
              <input class="c-web-form__input" type="tel" name="phoneNumber" id="c-web-form-phoneNumber" maxlength="20" />
            </div>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783480003" value="18783480003" />
            <label class="c-web-form__label" for="18783480003">General Interest</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783454003" value="18783454003" />
            <label class="c-web-form__label" for="18783454003">Bay Village Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783473003" value="18783473003" />
            <label class="c-web-form__label" for="18783473003">Chardon Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783452003" value="18783452003" />
            <label class="c-web-form__label" for="18783452003">Pepper Pike Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783464003" value="18783464003" />
            <label class="c-web-form__label" for="18783464003">Hemly Hardware</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783462003" value="18783462003" />
            <label class="c-web-form__label" for="18783462003">Bannockburn, IL Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783457003" value="18783457003" />
            <label class="c-web-form__label" for="18783457003">Bainbridge Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783458003" value="18783458003" />
            <label class="c-web-form__label" for="18783458003">Aurora Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783451003" value="18783451003" />
            <label class="c-web-form__label" for="18783451003">Barrington, IL Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783467003" value="18783467003" />
            <label class="c-web-form__label" for="18783467003">Twinsburg Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783459003" value="18783459003" />
            <label class="c-web-form__label" for="18783459003">University Hts. Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783449003" value="18783449003" />
            <label class="c-web-form__label" for="18783449003">Chagrin Falls Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783460003" value="18783460003" />
            <label class="c-web-form__label" for="18783460003">Lake Bluff, IL Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783475003" value="18783475003" />
            <label class="c-web-form__label" for="18783475003">Glenview, IL Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783479003" value="18783479003" />
            <label class="c-web-form__label" for="18783479003">Kelley&#39;s Is. Gen. Store</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783477003" value="18783477003" />
            <label class="c-web-form__label" for="18783477003">Island Hardware</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783469003" value="18783469003" />
            <label class="c-web-form__label" for="18783469003">Local Roots Market</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="21943596003" value="21943596003" />
            <label class="c-web-form__label" for="21943596003">Purplebrown Farm Store</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783446003" value="18783446003" />
            <label class="c-web-form__label" for="18783446003">Strongsville Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783455003" value="18783455003" />
            <label class="c-web-form__label" for="18783455003">Avon Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783447003" value="18783447003" />
            <label class="c-web-form__label" for="18783447003">Brecksville Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="21943593003" value="21943593003" />
            <label class="c-web-form__label" for="21943593003">Barnes Sewing Center</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" name="groupIds[]" type="checkbox" id="18783445003" value="18783445003" />
            <label class="c-web-form__label" for="18783445003">Rocky River Heinen&#39;s</label>
          </div>
          <div class="c-web-form__control-group">
            <input class="c-web-form__checkbox" type="checkbox" id="c-web-form-agree" onchange="document.querySelector('.c-web-form__submit').toggleAttribute('disabled', !this.checked)" />
            <label class="c-web-form__label" for="c-web-form-agree">Yes! I want to receive text messages</label>
          </div>
          <div class="c-web-form__control-group">
            <button type="submit" class="c-web-form__submit" disabled>Sign Up</button>
          </div>
          <p class="c-success-message">Thank you for signing up!</p>
          <div class="c-web-form__footer">
            Message and data rates may apply.
            <br />
            Recurring messages subscription.
            <br />
            <a class="c-web-form__terms-link" href="https://www.eztexting.com/terms">Click to view Terms & Privacy.</a>
            <br />
            Available in USA and Canada.
            <br />
            Powered by <span class="c-web-form-semibold-text">SPA EZ</span>
          </div>
        </form>
      </div>
      <SectionTitle title={slice.slice.primary.title.raw} />
      <div
        className='ctct-inline-form w-full md:w-2/3'
        data-form-id='1bb8f4a4-baf2-4ff3-ac23-61bbad24056c'
      >
        <div className='ctct-form-container ctct-form-embed form_0'>
          <div className='ctct-form-defaults' data-qe-id='form-background'>
            <div
              id='success_message_0'
              className='ctct-form-success'
              style={{ display: `none` }}
              data-qe-id='success-message'
            >
              <h2 className='ctct-form-header'>Thanks for signing up!</h2>
              <p className='ctct-form-text'>
                You can unsubscribe at any time using the Unsubscribe link at
                the bottom of every email.
              </p>
            </div>
            <form
              className='ctct-form-custom'
              id='ctct_form_0'
              autoComplete='on'
              data-qe-id='form-data'
            >
              <h2
                data-qe-id='form-title'
                className='ctct-form-header py-4 text-2xl'
              >
                Sign up!
              </h2>
              <p data-qe-id='form-description' className='ctct-form-text py-8'>
                Sign up to be notified of upcoming sharpening events!
              </p>
              <div id='email_address_field_0' className='ctct-form-field'>
                <label
                  data-qe-id='form-label-email'
                  id='email_address_label_0'
                  htmlFor='email_address_0'
                  className='ctct-form-label ctct-form-required'
                >
                  Email
                </label>
                <div
                  className='ctct-form-errorMessage'
                  data-qe-id='form-error-email'
                  style={{ display: `none` }}
                ></div>
                <input
                  className='ctct-form-element'
                  data-qe-id='form-input-email'
                  id='email_address_0'
                  type='email'
                  name='email_address'
                  defaultValue=''
                  maxLength='80'
                />
              </div>
              <fieldset
                id='list_memberships_field_0'
                className='ctct-form-lists'
              >
                <legend
                  id='list_memberships_label_0'
                  className='ctct-form-required ctct-form-lists-legend'
                >
                  Email Lists
                </legend>
                <div
                  className='ctct-form-errorMessage'
                  data-qe-id='form-error-list_memberships'
                  style={{ display: `none` }}
                ></div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Bassett's Market Port Clinton Customers"
                    id='email_list_0_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='61cc7010-f857-11e6-9d0a-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Bassett's Market Port Clinton Customers"
                    htmlFor='email_list_0_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Bassett's Market Port Clinton Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id='form-input-list-Countryside Customers'
                    id='email_list_1_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='abcf1660-f477-11e6-b240-d4ae529a8250'
                  />
                  <label
                    data-qe-id='form-label-list-Countryside Customers'
                    htmlFor='email_list_1_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Countryside Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id='form-input-list-General Interest'
                    id='email_list_2_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='bc42d6b0-f465-11e6-8cda-d4ae527536ce'
                  />
                  <label
                    data-qe-id='form-label-list-General Interest'
                    htmlFor='email_list_2_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    General Interest
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Aurora Customers"
                    id='email_list_3_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='dbee5970-f484-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Aurora Customers"
                    htmlFor='email_list_3_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Aurora Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Avon Customers"
                    id='email_list_4_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='dfca3410-f484-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Avon Customers"
                    htmlFor='email_list_4_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Avon Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Bainbridge Customers"
                    id='email_list_5_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='e5bdf230-f484-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Bainbridge Customers"
                    htmlFor='email_list_5_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Bainbridge Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Bannockburn Customers"
                    id='email_list_6_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='80d9c8e0-f6c7-11e6-ae68-d4ae52754dbc'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Bannockburn Customers"
                    htmlFor='email_list_6_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Bannockburn Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Barrington Customers"
                    id='email_list_7_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='8536e170-f6c7-11e6-ae68-d4ae52754dbc'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Barrington Customers"
                    htmlFor='email_list_7_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Barrington Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Bay Village Customers"
                    id='email_list_8_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='eb152230-f484-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Bay Village Customers"
                    htmlFor='email_list_8_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Bay Village Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Brecksville Customers"
                    id='email_list_9_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='f1e49a50-f484-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Brecksville Customers"
                    htmlFor='email_list_9_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Brecksville Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Chagrin Falls Customers"
                    id='email_list_10_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='f7dff990-f484-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Chagrin Falls Customers"
                    htmlFor='email_list_10_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Chagrin Falls Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Chardon Customers"
                    id='email_list_11_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='fcb3efd0-f484-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Chardon Customers"
                    htmlFor='email_list_11_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Chardon Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Glenview Customers"
                    id='email_list_12_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='899c8580-f6c7-11e6-ae68-d4ae52754dbc'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Glenview Customers"
                    htmlFor='email_list_12_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Glenview Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Hudson Customers"
                    id='email_list_13_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='17b56390-f485-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Hudson Customers"
                    htmlFor='email_list_13_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Hudson Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Lake Bluff Customers"
                    id='email_list_14_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='8dfad690-f6c7-11e6-ae68-d4ae52754dbc'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Lake Bluff Customers"
                    htmlFor='email_list_14_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Lake Bluff Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Mayfield Village Customers"
                    id='email_list_15_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='1e20c2b0-f485-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Mayfield Village Customers"
                    htmlFor='email_list_15_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Mayfield Village Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Mentor Customers"
                    id='email_list_16_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='2253e510-f485-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Mentor Customers"
                    htmlFor='email_list_16_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Mentor Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Middleburg Heights Customers"
                    id='email_list_17_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='2b522410-f485-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Middleburg Heights Customers"
                    htmlFor='email_list_17_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Middleburg Heights Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Pepper Pike Customers"
                    id='email_list_18_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='32000a70-f485-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Pepper Pike Customers"
                    htmlFor='email_list_18_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Pepper Pike Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Rocky River Customers"
                    id='email_list_19_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='369745d0-f485-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Rocky River Customers"
                    htmlFor='email_list_19_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Rocky River Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Shaker Heights Customers"
                    id='email_list_20_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='3ce61c40-f485-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Shaker Heights Customers"
                    htmlFor='email_list_20_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Shaker Heights Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Strongsville Customers"
                    id='email_list_21_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='7d8932f0-f485-11e6-98e6-d4ae528eb986'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Strongsville Customers"
                    htmlFor='email_list_21_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Strongsville Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Twinsburg Customers"
                    id='email_list_22_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='71bc9d10-f6c7-11e6-ae68-d4ae52754dbc'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Twinsburg Customers"
                    htmlFor='email_list_22_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Twinsburg Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's University Heights Customers"
                    id='email_list_23_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='7727f150-f6c7-11e6-ae68-d4ae52754dbc'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's University Heights Customers"
                    htmlFor='email_list_23_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's University Heights Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Heinen's Willoughby Customers"
                    id='email_list_24_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='7c5dddb0-f6c7-11e6-ae68-d4ae52754dbc'
                  />
                  <label
                    data-qe-id="form-label-list-Heinen's Willoughby Customers"
                    htmlFor='email_list_24_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Heinen's Willoughby Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id='form-input-list-Hemly Hardware'
                    id='email_list_25_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='14651060-681d-11e9-8268-d4ae528ec60a'
                  />
                  <label
                    data-qe-id='form-label-list-Hemly Hardware'
                    htmlFor='email_list_25_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Hemly Hardware
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id='form-input-list-Island Hardware Customers'
                    id='email_list_26_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='dc44a6b0-22ae-11e7-bb7c-d4ae52a6892e'
                  />
                  <label
                    data-qe-id='form-label-list-Island Hardware Customers'
                    htmlFor='email_list_26_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Island Hardware Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id="form-input-list-Kelley's Island General Store"
                    id='email_list_27_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='ffe2f070-6e72-11e8-8016-d4ae528ec60a'
                  />
                  <label
                    data-qe-id="form-label-list-Kelley's Island General Store"
                    htmlFor='email_list_27_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Kelley's Island General Store
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id='form-input-list-Local Roots Wooster Customers'
                    id='email_list_28_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='7595b110-26b5-11e7-b009-d4ae528ec60a'
                  />
                  <label
                    data-qe-id='form-label-list-Local Roots Wooster Customers'
                    htmlFor='email_list_28_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Local Roots Wooster Customers
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id='form-input-list-Ohio City Provisions'
                    id='email_list_29_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='22a526a0-e1e1-11e8-b000-d4ae52754dbc'
                  />
                  <label
                    data-qe-id='form-label-list-Ohio City Provisions'
                    htmlFor='email_list_29_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    Ohio City Provisions
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id='form-input-list-The Olive Scene Chagrin Falls'
                    id='email_list_30_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='1b7c2e74-681d-11e9-8268-d4ae528ec60a'
                  />
                  <label
                    data-qe-id='form-label-list-The Olive Scene Chagrin Falls'
                    htmlFor='email_list_30_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    The Olive Scene Chagrin Falls
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id='form-input-list-The Olive Scene Rocky River'
                    id='email_list_31_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='1f980596-681d-11e9-8268-d4ae528ec60a'
                  />
                  <label
                    data-qe-id='form-label-list-The Olive Scene Rocky River'
                    htmlFor='email_list_31_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    The Olive Scene Rocky River
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id='form-input-list-The Olive Scene Strongsville'
                    id='email_list_32_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='26610de6-681d-11e9-8268-d4ae528ec60a'
                  />
                  <label
                    data-qe-id='form-label-list-The Olive Scene Strongsville'
                    htmlFor='email_list_32_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    The Olive Scene Strongsville
                  </label>
                </div>

                <div className='ctct-form-listitem'>
                  <input
                    className='ctct-form-checkbox'
                    data-qe-id='form-input-list-The Olive Scene Vermilion'
                    id='email_list_33_0'
                    type='checkbox'
                    name='emailList'
                    defaultValue='2a830c8a-681d-11e9-8268-d4ae528ec60a'
                  />
                  <label
                    data-qe-id='form-label-list-The Olive Scene Vermilion'
                    htmlFor='email_list_33_0'
                    className='ctct-form-listname ctct-form-checkbox-label'
                  >
                    The Olive Scene Vermilion
                  </label>
                </div>
              </fieldset>
              <div
                id='error_message_0'
                className='ctct-form-error'
                style={{ display: `none` }}
              >
                <p className='ctct-form-errorMessage'>
                  Sorry, we could not complete your sign-up. Please contact us
                  to resolve this.
                </p>
              </div>
              <div
                id='network_error_message_0'
                className='ctct-form-error'
                style={{ display: `none` }}
              >
                <p className='ctct-form-errorMessage'>
                  Operation timed out, please try again.
                </p>
              </div>
              <div id='gdpr_text'>
                <p
                  className='ctct-gdpr-text py-4 text-sm'
                  data-qe-id='form-gdpr-text'
                >
                  By submitting this form, you are consenting to receive
                  marketing emails from: Noon Sharpening, Inc., 52 Vincent
                  Avenue, Northfield, OH, 44067, US,
                  http://www.noonsharpening.com. You can revoke your consent to
                  receive emails at any time by using the SafeUnsubscribe® link,
                  found at the bottom of every email.
                  <a
                    href='https://www.constantcontact.com/legal/service-provider'
                    target='_blank'
                    rel='noreferrer noopener'
                    data-qe-id='form-service-link'
                    className='ctct-form-footer-link'
                  >
                    Emails are serviced by Constant Contact.
                  </a>
                </p>
              </div>
              <button
                data-qe-id='form-button'
                type='submit'
                className='ctct-form-button my-4'
              >
                Sign Up!
              </button>
              <div
                className='g-recaptcha'
                id='ctct_recaptcha_0'
                data-sitekey='6LfHrSkUAAAAAPnKk5cT6JuKlKPzbwyTYuO8--Vr'
                data-size='invisible'
              >
                <div
                  className='grecaptcha-badge'
                  data-style='bottomright'
                  style={{
                    width: `256px`,
                    height: `60px`,
                    display: `block`,
                    transition: `right 0.3s ease 0s`,
                    position: `fixed`,
                    bottom: `14px`,
                    right: `-186px`,
                    boxShadow: `gray 0px 0px 5px`,
                    borderRadius: `2px`,
                    overflow: `hidden`,
                  }}
                >
                  <div className='grecaptcha-logo'>
                    <iframe
                      title='reCAPTCHA'
                      src='https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LfHrSkUAAAAAPnKk5cT6JuKlKPzbwyTYuO8--Vr&amp;co=aHR0cDovL3d3dy5ub29uc2hhcnBlbmluZy5jb206ODA.&amp;hl=en&amp;v=qljbK_DTcvY1PzbR7IG69z1r&amp;size=invisible&amp;cb=89fquz4kr9i4'
                      role='presentation'
                      name='a-h9x7g46wzjn7'
                      scrolling='no'
                      sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation'
                      width='256'
                      height='60'
                      frameBorder='0'
                    ></iframe>
                  </div>
                  <div className='grecaptcha-error'></div>
                  <textarea
                    id='g-recaptcha-response'
                    name='g-recaptcha-response'
                    className='g-recaptcha-response'
                    style={{
                      width: `250px`,
                      height: `40px`,
                      border: `1px solid rgb(193, 193, 193)`,
                      margin: `10px 25px`,
                      padding: `0px`,
                      resize: `none`,
                      display: `none`,
                    }}
                  ></textarea>
                </div>
                <iframe style={{ display: `none` }}></iframe>
              </div>
            </form>
            <div className='ctct-form-footer my-4 flex w-full items-center justify-center'>
              <a
                href='http://www.constantcontact.com/index.jsp?cc=forms_popup'
                target='_blank'
                rel='nofollow noopener noreferrer'
                className='ctct-form-footer-link'
              >
                <img
                  src='https://static.ctctcdn.com/lp/images/standard/logos/logo-ctct-white.svg?v=2020'
                  alt=''
                  className='ctct-form-footer-img text-center'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConstantContactSlice;
