import React from 'react';
import RbrsGCal from './components/rbrsGCal';
import AlternateGrid from './components/slices/alternateGrid';
import BlogRollSlice from './components/slices/blogRollSlice';
import CallToActionSlice from './components/slices/callToActionSlice';
import ConstantContactSlice from './components/slices/constantContactSlice';
import EventScheduleSlice from './components/slices/eventScheduleSlice';
import FaqSection from './components/slices/faqSection';
import FormSectionSlice from './components/slices/formSectionSlice';
import GiftupSlice from './components/slices/giftupSlice';
import LinksCollection from './components/slices/linksCollectionSlice';
import SectionFeaturingLink from './components/slices/sectionFeaturingLink';

export const SliceZone = ({ slices }) => {
  const sliceComponents = {
    alternate_grid: AlternateGrid,
    'call-to-action': CallToActionSlice,
    'form-section': FormSectionSlice,
    section_featuring_link: SectionFeaturingLink,
    faq_section: FaqSection,
    'links-collection': LinksCollection,
    'constant-contact-signup-form': ConstantContactSlice,
    'giftup-gift-cards': GiftupSlice,
    'blog-roll': BlogRollSlice,
    'event-schedule': RbrsGCal,
  };

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice=${index}`} />;
    }
    return null;
  });
};
